import React from 'react'
import { useTranslation } from "react-i18next";
import Phone from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import es from "react-phone-number-input/locale/es";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";

const locale = {es, en, pt};

const InnerPhoneInput = React.forwardRef((props, ref) => {
  const newProps = { ...props };
  return (
    <input
      ref={ref}
      {...newProps}
      className='w-full border-none rounded-r-lg focus:outline-none focus:border-none focus:ring-0'
    />
  );
});

const Component = (props) => {
  const { t, i18n } = useTranslation();
  const [language, country] = i18n.language.split("-");

  const onChange = (value) => {
    props.onChange(value)
  }

  const labels = locale[language] || locale.es;

  return (
    <React.Fragment>
      <div className={`pl-2 w-full outline-none rounded-lg border ${props.invalid ? 'border-red-400 bg-white placeholder-red-300' : 'border-gray-300'} focus-within:border-blue-400 focus-within:ring-1 focus-within:ring-blue-500 focus-within:outline-none active:outline-none placeholder-gray-400`}>
        <Phone
          international={true}
          labels={labels}
          name={props.name}
          value={props.value || ''}
          onChange={(value) => onChange(value)}
          defaultCountry={country || "ar"}
          placeholder=""
          focusInputOnCountrySelection={false}
          inputComponent={InnerPhoneInput}
        />
      </div>
      <small
        className="form-text"
        dangerouslySetInnerHTML={{
          __html: t("phone_hint", { context: country.toLowerCase() }),
        }}
      />
    </React.Fragment>
  )
}

export default Component;